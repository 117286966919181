
.blue{
  color: #135ba2;
}

#projets li:hover{
  transform: translateY(-12px);
  transition: all 0.4s ease-in-out;
}
button:hover{
color:#135ba2;
font-weight: bolder;
}

.shadow-card{
  filter: drop-shadow(0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1));
 
}