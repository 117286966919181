@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.scrollerBandeau {
  display: inline-block;
  animation: scroll 240s linear infinite;
  background: linear-gradient(180deg, var(--slate-800), var(--slate-900));
}

.scrollerBandeau:hover {
  animation-play-state: paused;
}