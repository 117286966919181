
.About {
    background-color: #111;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .About .text-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.drop-img{
  filter: drop-shadow(4px 4px 4px gray);
}

@media (max-width: 767.98px) {
  .About {
    height: auto;
  }
}