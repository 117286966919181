/* Loader.css */
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
  }
  
  .loader-content {
    width: 550px;
    height: 550px;
    animation: grow 1.7s ease;
    color: white;
  }
  
  .loader-image {
    width: 100%;
    height: 100%;
  }
  
  @keyframes grow {
    from {
      transform: scale(0.5); 
      opacity: 0; 
    }
    to {
      transform: scale(1); 
      opacity: 1; 
    }
  }
  
  @media  (max-width: 768px)  {
    .loader-container {
      display: none;
    }
  }