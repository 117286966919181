.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: linear-gradient(180deg, var(--slate-800), var(--slate-900));    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  

  .scroll-to-top-button.show {
    opacity: 0.3;
  }
  .scroll-to-top-button.show:hover {
    opacity: 0.8;
  }

  .scroll-to-top-button i {
    font-size: 20px;
  }
  