
.WelcomeBanner {
    background-color: #111;
    color: #f4f3f3;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    
  }
  
.welcome-text{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 34px;
  }

  @media (max-width: 767.98px) {
    .welcome-text{
      font-size: 20px;
    }

  }
  @media (max-width: 320px){
    .welcome-text{
      font-size: 16px;
    }
  }